import React from 'react';
import { Link } from 'gatsby';
import './SimpleCard.scss';

const SimpleCard = props => {
  const {
    image,
    title,
    text,
    href,
    subtext,
    className,
  } = props;

  return (
    <div className={`simple-card card ${className}`}>
      <Link to={href} className="simple-card-container card-container">
        {image && (
          <div className="simple-card-image">
            <img src={image} alt={title} />
          </div>
        )}
        <div className="simple-card-title card-title">{title}</div>
        <div className="simple-card-text card-text">{text}</div>
        {subtext && (
          <div className="simple-card-subtext">{subtext}</div>
        )}
      </Link>
    </div>
  );
};

SimpleCard.defaultProps = {
  className: '',
};

export default SimpleCard;
