import React from 'react';
import { graphql } from 'gatsby';
import ContentCards from '../components/ContentCards';
import SimpleCard from '../components/SimpleCard/SimpleCard';
import Layout from '../layout';
import './discover-features.scss';

const DiscoverFeatures = (props) => {
  const {
    data: {
      dataYaml: { features },
    },
    location,
  } = props;

  return (
    <Layout pathname={location.pathname} withWedges={false} title="Features" className="features-page">
      <div className="features-page-header">
        <p className="features-page-header-title">
          Features
        </p>
      </div>
      <div className="features-page-content">
        <p className="features-page-content-title">
          <span style={{ color: '#d2519d', fontWeight: 'bold' }}>Discover</span> all features
        </p>
        <div className="features-page-content-description">
          <p>
            MLReef unifies a complete ML pipeline in one solution to address all
            issues like managing teams and members,
            project creation,
            versioning of data and models and much more
          </p>
        </div>
        <ContentCards />
        <p className="features-page-content-title">
          <span style={{ color: '#d2519d' }}>Click</span> on each feature to find out more
        </p>
        <div className="features-page-content-description">
          <p>
            MLReef aims to solve most of the pressing problems of modern Machine Learning,
            such as collaboration, efficiency, reproducibility and access to data and code.
          </p>
        </div>
      </div>
      <div className="banner-short dark card mt-4">
        <div className="banner-short-container card-container banner">
          <div className="">
            <p>
              MLReef takes a new approach on ML development by structuring the ML life
              cycle into <a>modules</a>. Each module is an atomic <a>git repository</a> that enables
              orchestrated, shareable and scalable work on ML projects.
            </p>
            <div className="banner-short-content-btn-group">
              <button type="button" className="banner-short-content-btn btn orange">
                Models
              </button>
              <button type="button" className="banner-short-content-btn btn magenta">
                Data Operations
              </button>
              <button type="button" className="banner-short-content-btn btn purple">
                Data Visualizations
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="features-page-billing-profiles">
        {features && features.map(feat => (
          <SimpleCard
            key={`feature-${feat.title}`}
            image={feat.image}
            title={feat.title}
            text={feat.text}
            href={feat.href}
          />
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query FetchFeaturesPageData {
    dataYaml {
      features {
        image
        title
        text
        href
      }
    }
  }
  `;

export default DiscoverFeatures;
